import { firebase, DashboardSideNav } from "@project/shared";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { styled } from "linaria/react";
import { Layout } from "antd";

import { useContext } from "react";
import { AuthContext } from "../../../utils/AuthContext";

const { Sider } = Layout;

const SiderWrapper = styled(Sider)`
  min-width: 256px !important;
  max-width: 256px !important;
  flex: 0 0 256px !important;
  min-height: 100vh;

  & .ant-layout-sider-children {
    background: #ffffff;
  }
`;

const Sidebar = () => {
  const router = useRouter();
  const { user, isSuperAdmin } = useContext(AuthContext);
  const { t } = useTranslation();

  const handleSignOut = async () => {
    await firebase.auth().signOut();
    router.push("/login");
  };
  const menus = [
    {
      key: "1",
      className: router.pathname.startsWith("/articles") && "active",
      name: t("Article management"),
      onClick: () => router.push("/articles"),
    },
    {
      key: "2",
      className: router.pathname.startsWith("/news") && "active",
      name: t("News management"),
      onClick: () => router.push("/news"),
    },
    {
      key: "3",
      className: router.pathname.startsWith("/events") && "active",
      name: t("Event management"),
      onClick: () => router.push("/events"),
    },
    {
      key: "4",
      className: router.pathname.startsWith("/links") && "active",
      name: t("Link management"),
      onClick: () => router.push("/links"),
    },
    {
      key: "5",
      className: router.pathname.startsWith("/tags") && "active",
      name: t("Tag management"),
      onClick: () => router.push("/tags"),
      hide: !isSuperAdmin,
    },
    {
      key: "6",
      className: router.pathname.startsWith("/contact") && "active",
      name: t("Contact"),
      onClick: () => router.push("/contact"),
      hide: !isSuperAdmin,
    },
    {
      key: "7",
      className: router.pathname.startsWith("/change-password") && "active",
      onClick: () => {
        router.push("/change-password");
      },
      name: t("Change password"),
    },
    {
      key: "8",
      className: router.pathname.startsWith("/change-email") && "active",
      onClick: () => router.push("/change-email"),
      name: t("Change email"),
      hide: isSuperAdmin,
    },
    {
      key: "9",
      onClick: handleSignOut,
      name: t("Logout"),
    },
  ];
  if (!user) {
    return <></>;
  }
  return (
    <SiderWrapper trigger={null} collapsed={false}>
      <DashboardSideNav menu={menus} href="/articles" />
    </SiderWrapper>
  );
};

export { Sidebar };
